import React from 'react'
import PropTypes from 'prop-types'

import Header from '~/components/Header'
import Menu from '~/components/Menu'
import Footer from '~/components/Footer'

import '~/styles/Global.scss'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Menu />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout