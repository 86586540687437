import React, { useEffect } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import '~/styles/Menu.scss'

const Menu = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=en&widgetTheme=light&autoMode=false";
		script.async = true;

		document.body.appendChild(script);
	});

	return (
		<aside className="global-menu">
			<div id="ytWidget"></div>
			<nav className="global-menu-nav">
				<li><AnchorLink className="global-menu-nav-link" href="#about">About COVID–19 kit</AnchorLink></li>
				<li><AnchorLink className="global-menu-nav-link" href="#disease">What is COVID-19</AnchorLink></li>
				<li><AnchorLink className="global-menu-nav-link" href="#instruction">How to use</AnchorLink></li>
				<li><AnchorLink className="global-menu-nav-link" href="#video">Watch video</AnchorLink></li>
				<li><AnchorLink className="global-menu-nav-link" href="#register">Register your kit</AnchorLink></li>
				<li><AnchorLink className="global-menu-nav-link" href="#certificates">Certificates</AnchorLink></li>
			</nav>
			<div className="global-menu-contacts">
				<div className="global-menu-contacts-email">
					<a href="mailto:info@bostonbiolab.com">info@bostonbiolab.com</a>
				</div>
				<div className="global-menu-contacts-phone">
					<div>US <strong>+1-561-631-2143</strong></div>
					<div>EU <strong>+49-6131-276-6244</strong></div>
				</div>
				<div className="global-menu-contacts-address">
					BOSTON BIO LAB INC.<br />
					1395 Brickell Ave. #800<br />
					Miami, FL<br />
					USA 33131
				</div>
			</div>
		</aside>
	)
}

export default Menu
