import React from 'react'

import YoutubeIcon from '~/svgs/youtube.svg'
import InstagramIcon from '~/svgs/instagram.svg'
import FacebookIcon from '~/svgs/facebook.svg'

import '~/styles/Footer.scss'

const Footer = () => (
	<footer className="global-footer">
		<div className="global-footer-copyright">
			<div><span className="global-footer-copyright-c">©</span> 2020 <strong>BOSTON BIO LAB INC.</strong> All rights reserved</div>
			<div><a href="https://damlab.ru" target="_blank" rel="noopener noreferrer">Digital solutions production</a></div>
			<div>Переведено сервисом «<a href="http://translate.yandex.ru" target="_blank" rel="noopener noreferrer">Яндекс.Переводчик</a>»</div>
		</div>
		<div className="global-footer-email">
			<a href="mailto:info@bostonbiolab.com">info@bostonbiolab.com</a>
		</div>
		<div className="global-footer-phone">
			<div>US <strong>+1-561-631-2143</strong></div>
			<div>EU <strong>+49-6131-276-6244</strong></div>
		</div>
		<div className="global-footer-social">
			<ul class="global-footer-social-list">
				<li className="global-footer-social-list-item"><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCEPVOXVA3XnI3zsE79ubcAQ/"><YoutubeIcon className="product-intro-social-item-icon product-intro-social-item-icon-instagram" /></a></li>
				<li className="global-footer-social-list-item"><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/boston_bio_lab_inc/"><InstagramIcon className="product-intro-social-item-icon product-intro-social-item-icon-youtube" /></a></li>
				<li className="global-footer-social-list-item"><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/bostonbio.lab"><FacebookIcon className="product-intro-social-item-icon product-intro-social-item-icon-facebook" /></a></li>
			</ul>
		</div>
	</footer>
)

export default Footer
